@import "oreo/global/colors_v3";
@import "oreo/editor/lessons/translatable_lesson_export";

@media print {
    body.public {
        background: white !important;
    }

    .cf-challenge-overlay-blank.fillable-rect-on-image.multiple_choice,
    .cf_answer_list[renderer="image_hotspot"] button.hotspot.fillable-rect-on-image {
        background: none !important;
        background-color: transparent !important;
        box-shadow: none !important;
        border: 2px solid $COLOR_V3_CORAL !important;
    }

    .cf_answer_list[renderer="poll"] button,
    .cf_answer_list[renderer="poll"] button outline {
        background: none !important;
        background-color: transparent !important;
        box-shadow: none !important;
    }
}
