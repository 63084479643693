translatable-lesson-export,
.translatable-lesson-export {
    display: block;
    padding: 12px;

    table {
        border-collapse: collapse;
        margin-top: 18px;
        width: 100%;

        pre {
            word-break: break-word;
            white-space: pre-wrap;
            font-family: inherit;
            font-size: 16px;
        }

        td {
            vertical-align: top;
            padding: 4px;
        }
    }

    .frame-image,
    .frame-canvas {
        text-align: center;
    }

    .image-preview {
        max-width: 350px;
        display: inline-block;
        background-color: #fff;
    }

    .translatable-frame-wrapper {
        margin-top: 100px;

        translatable-frame-export {
            border-top: 1px solid #999999;
            position: relative;
            display: block;

            .frame_list {
                height: 600px;
                position: relative;
                overflow-y: scroll;
                overflow-x: hidden;
                max-width: 750px;
                border: 1px solid #999999;
                border-radius: 6px;
                margin: auto;
                background-color: #ffffff;

                cf-component-overlay {
                    display: block !important;
                }
                // since we don't have the purple background, we make
                // this look like the editor
                .cf-tile-prompt {
                    border: 1px solid $COLOR_V3_PURPLE;
                }
            }
        }
    }
}
